import type { ContractPayment } from 'lc-repository/entities'
import type { HouseDetails, House, Locale } from 'lc-services/types'
import type {
  OwnerContract,
  Advisor,
  ContactInformation,
  UserData,
} from '~/types/reservationAgreement/types'

export const useReservationAgreement = async (
  contractId: string,
  flowType = 'client',
) => {
  const {
    $api,
    $lcServicesClientContract: {
      clientContractController: controller,
      clientContractPresenter: presenter,
    },
  } = useNuxtApp()
  const { locale } = useI18n<unknown, Locale>()

  const isClientFlow = computed(() =>
    ['client', 'resume-client'].includes(flowType),
  )

  const getClientContract = async (clientContractId: string) => {
    await controller.getClientContract(clientContractId)
    if (!presenter.vm.clientContract)
      throw createError({
        data: presenter.vm.clientContractError,
      })
    const {
      paymentTransactions,
      contractPayments,
      houses,
      reviewers: advisor,
      destinations,
      ...contract
    } = presenter.vm.clientContract
    const house = Array.isArray(houses) ? houses[0] : houses

    return {
      advisor,
      contractPayments: Array.isArray(contractPayments)
        ? contractPayments
        : [contractPayments],
      contract: {
        ...contract,
        destination: destinations,
        house,
        paymentTransactions,
      },
      house: {
        id: house.id,
        conciergeServiceOffer: contract.conciergerieOffer,
        slug: house.slug,
        name: house.name,
        privateToken: house.privateToken ?? '',
      },
    }
  }
  const getOwnerContract = async (ownerContractId: string) => {
    const {
      data: contract,
      included: { reviewers: advisor, houses: houseDetails, contractPayments },
    } = (await $api.v3.userOwnerContracts.read(
      ownerContractId,
      '?include=house,reviewer,contract_payments',
    )) as Awaited<{
      data: OwnerContract
      included: {
        reviewers: Advisor
        houses: HouseDetails
        contractPayments: ContractPayment[]
      }
    }>

    const { data: house } = (await $api.v3.house.getHouseBySlug({
      name: houseDetails?.id,
      token: houseDetails?.privateToken,
      fields: {
        house: ['id', 'conciergeServiceOffer'],
      },
      includes: [],
    })) as { data: House }

    return {
      advisor,
      contract: {
        ...contract,
        destination: {},
        house: houseDetails,
      },
      contractPayments: Array.isArray(contractPayments)
        ? contractPayments
        : [contractPayments],
      house,
    }
  }

  const { data, error, refresh } = await useAsyncData(
    `reservationAgreement-${contractId}`,
    async () => {
      const [contractData, userData] = await Promise.all([
        isClientFlow.value
          ? getClientContract(contractId)
          : getOwnerContract(contractId),
        getUserInformations($api, locale),
      ])

      if (!contractData) {
        throw createError({ statusCode: 404, message: 'Contract not found' })
      }
      if (presenter.vm.clientContractError) {
        throw createError({
          data: presenter.vm.clientContractError,
        })
      }

      const { advisor, contract, contractPayments, house } = contractData
      return {
        advisor,
        userData,
        contract: {
          ...contract,
          photo: contract.houseFirstPhotoUrl,
          contractPayments,
          houseKeeping: {
            household: (contract as OwnerContract).housekeepingHandledByLc,
            day: contract.housekeeping,
            hours: contract.housekeepingHours,
            price: Number(
              (contract as any).additionalHousekeepingHouseFee ?? '0',
            ),
            sheets: contract.sheetsReplacement,
            towels: contract.towelsReplacement,
            essentials: contract.cookingEssentials,
            toiletries: contract.bathroomProducts,
          },
        },
        house,
      }
    },
  )

  const contract = computed(() => data.value?.contract)
  const advisor = computed(() => data.value?.advisor ?? ({} as Advisor))
  const house = computed(() => data.value?.house ?? ({} as House))
  const userData = computed(() => data.value?.userData ?? ({} as UserData))
  const contactInformation = ref<ContactInformation>(
    contract.value
      ? getContactInformation(data.value, presenter.vm.contactInformation)
      : presenter.vm.contactInformation,
  )

  /**
   * @desc store contact information between client-flow steps
   * before sending them to the back-end (end of step 2)
   */
  const storeContactInformation = (values: Partial<ContactInformation>) => {
    contactInformation.value = {
      ...getContactInformation(data.value, contactInformation.value),
      ...Object.fromEntries(
        Object.entries(values).filter(([, val]) => Boolean(val)),
      ),
    }
    // because the identity-documents usecase is called server-side, we need to update the vm client-side
    presenter.displayIdentityDocumentInformation(contactInformation.value)
  }

  const refetch = async () => {
    await refresh()
    if (data.value) {
      // as the contract is refreshed, so must be the contactInformation linked to it
      contactInformation.value = {} as ContactInformation
      storeContactInformation({})
    }
  }

  return {
    advisor,
    contactInformation,
    contract,
    error,
    house,
    isClientFlow,
    refetch,
    storeContactInformation,
    userData,
  }
}
