import { countriesList } from '~/assets/js/countriesList'

export const useCoutriesListWithoutLocale = (locale: Ref<'fr' | 'en'>) => {
  const countries = computed(() => {
    return countriesList
      .map((country) => ({
        value: country.iso,
        label: country.country[locale.value],
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  })

  return {
    countries,
  }
}
