import { findCountryByCode } from 'lc-services/services/country'
import type {
  ContactInformation,
  Contract,
  UserData,
} from '~/types/reservationAgreement/types'

export const getContactInformation = (
  reservationAgreement: { contract: unknown; userData: UserData } | null,
  contactInformation: ContactInformation,
): ContactInformation => {
  if (!reservationAgreement) return {} as ContactInformation
  const { userData, contract } = reservationAgreement as {
    userData: UserData
    contract: Contract
  }

  const common: ContactInformation = {
    address: contract.clientAddress || userData.address,
    birthdate: contract.clientBirthdate || userData.birthdate,
    city: contract.clientCity || userData.city,
    civility: getCivility(contract.clientCivility || userData.civility),
    companyName: null,
    companyNumber: null,
    country:
      findCountryByCode(contract.clientCountry || userData.country)
        ?.isoAlpha2Code ?? '',
    email: contract.clientEmail || userData.email,
    firstname: (contract.clientFirstname || userData.firstName || '').replace(
      /,/g,
      '',
    ),
    identityDocumentExpirationDate:
      contract.clientIdentityDocumentExpirationDate ||
      userData.identityDocumentExpirationDate,
    identityDocumentFiles: [],
    identityDocumentNumber:
      contract.clientIdentityDocumentNumber || userData.identityDocumentNumber,
    identityDocumentType:
      contract.clientIdentityDocumentType || userData.identityDocumentType,
    lastname: (contract.clientLastname || userData.lastName || '').replace(
      /,/g,
      '',
    ),
    nationality:
      findCountryByCode(contract.clientNationality || userData.nationality)
        ?.isoAlpha2Code ?? '',
    phone: contract.clientPhone || userData.phone,
    postalCode: contract.clientPostalCode || userData.postalCode,
    type: userData.entity || null,
    // ensure only non-null properties are filled-in from stored contact informations
    ...Object.fromEntries(
      Object.entries(contactInformation).filter(([, value]) => Boolean(value)),
    ),
  }
  if (common.type !== 'company') return common

  return {
    ...common,
    address:
      contract.clientCompanyAddress ||
      userData.companyAddress ||
      common.address,
    birthdate:
      contract.clientCompanyRepresentativeBirthdate || common.birthdate,
    city: contract.clientCompanyCity || userData.companyCity || common.city,
    civility: getCivility(
      contract.clientCompanyRepresentativeCivility || common.civility,
    ),
    companyName:
      common.companyName || contract.clientCompanyName || userData.companyName,
    companyNumber:
      common.companyNumber ||
      contract.clientCompanySiret ||
      userData.companySiret,
    country:
      findCountryByCode(contract.clientCompanyCountry || userData.country)
        ?.isoAlpha2Code ?? '',
    email: contract.clientCompanyEmail || common.email,
    firstname: (
      contract.clientCompanyRepresentativeFirstname ||
      common.firstname ||
      ''
    ).replace(/,/g, ''),
    identityDocumentExpirationDate:
      contract.clientCompanyRepresentativeIdentityDocumentExpirationDate ||
      common.identityDocumentExpirationDate,
    identityDocumentNumber:
      contract.clientCompanyRepresentativeIdentityDocumentNumber ||
      common.identityDocumentNumber,
    identityDocumentType:
      contract.clientCompanyRepresentativeIdentityDocumentType ||
      common.identityDocumentType,
    lastname: (
      contract.clientCompanyRepresentativeLastname ||
      common.lastname ||
      ''
    ).replace(/,/g, ''),
    nationality:
      findCountryByCode(
        contract.clientCompanyRepresentativeNationality ||
          common.nationality ||
          '',
      )?.isoAlpha2Code ?? '',
    phone: contract.clientCompanyPhone || common.phone,
    postalCode: contract.clientCompanyPostalCode || common.postalCode,
  }
}

// API may send back a localized value such as "Mme" or "Mrs"
const getCivility = (
  civility: string | undefined | null,
): 'mr' | 'mrs' | 'unspecified' => {
  switch ((civility ?? '').toLowerCase()) {
    case 'm':
    case 'mr':
      return 'mr'
    case 'mrs':
    case 'ms':
    case 'mme':
      return 'mrs'
    case 'non précisé':
    case 'unspecified':
    default:
      return 'unspecified'
  }
}
