import { $dayjs } from '@/utils/dayjs'
import { civilityOptions, nationalities } from '~/helpers/formSelectOptions'
import { useCoutriesListWithoutLocale } from '~/composables/useCoutriesListWithoutLocale'
import type { UserData } from '~/types/reservationAgreement/types'
import type { ClientApi } from '~/repository/config'
import type { Locale } from 'lc-services/types'

const formatData = (data: UserData, locale: Ref<Locale>) => {
  const { countries } = useCoutriesListWithoutLocale(locale)
  const formattedData: {
    country?: string
    civility?: string
    firstname?: string
    nationality?: string
    lastname?: string
    birthdate?: string
  } = {}

  formattedData.country =
    countries.value.find((country) => country.value === data.country)?.value ||
    ''
  formattedData.civility =
    civilityOptions.find((civility) => civility.value === data.civility)
      ?.value || ''
  formattedData.firstname = data.firstName
  formattedData.lastname = data.lastName
  formattedData.birthdate = data.birthdate
    ? $dayjs(data.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    : ''
  formattedData.nationality = nationalities('fr').find(
    (nationality) => nationality.value === data.nationality,
  )?.value

  const userData = Object.assign({}, data, formattedData)

  return userData as UserData
}

export const getUserInformations = async (
  $api: ClientApi,
  locale: Ref<'fr' | 'en'>,
): Promise<UserData> => {
  try {
    const res = await $api.v3.userInformation.read()
    const userData = formatData(res.data, locale)
    const userCompany = await $api.v1.userCompanies.read().then((res) => {
      if (res.data?.length) {
        const [company] = res.data

        return {
          civility: company.representativeCivility,
          firstName: company.representativeFirstName,
          lastName: company.representativeLastName,
          phone: company.phone,
          companyName: company.name,
          companySiret: company.siret,
          companyAddress: company.address,
          companyPostalCode: company.postalCode,
          companyCity: company.city,
          companyCountry: company.country,
        }
      }
      return null
    })

    return {
      ...userData,
      entity: userCompany ? 'company' : 'individual',
      ...userCompany,
    }
  } catch {
    return {
      address: '',
      bic: null,
      birthdate: '',
      city: '',
      civility: '',
      country: '',
      email: '',
      entity: 'individual',
      firstName: '',
      iban: '',
      lastName: '',
      nationality: '',
      phone: '',
      postalCode: '',
    } as UserData
  }
}
